@font-face {
  font-family: Figtree Variable;
  font-style: normal;
  font-display: swap;
  font-weight: 300 900;
  src: url("figtree-latin-ext-wght-normal.9ccfb569.woff2") format("woff2-variations");
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Figtree Variable;
  font-style: normal;
  font-display: swap;
  font-weight: 300 900;
  src: url("figtree-latin-wght-normal.d099c82d.woff2") format("woff2-variations");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Roboto Mono Variable;
  font-style: normal;
  font-display: swap;
  font-weight: 100 700;
  src: url("roboto-mono-cyrillic-ext-wght-normal.84a6bbcf.woff2") format("woff2-variations");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Roboto Mono Variable;
  font-style: normal;
  font-display: swap;
  font-weight: 100 700;
  src: url("roboto-mono-cyrillic-wght-normal.d54ef58a.woff2") format("woff2-variations");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Roboto Mono Variable;
  font-style: normal;
  font-display: swap;
  font-weight: 100 700;
  src: url("roboto-mono-greek-wght-normal.d4c8f0b9.woff2") format("woff2-variations");
  unicode-range: U+370-3FF;
}

@font-face {
  font-family: Roboto Mono Variable;
  font-style: normal;
  font-display: swap;
  font-weight: 100 700;
  src: url("roboto-mono-vietnamese-wght-normal.02219c7b.woff2") format("woff2-variations");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Roboto Mono Variable;
  font-style: normal;
  font-display: swap;
  font-weight: 100 700;
  src: url("roboto-mono-latin-ext-wght-normal.95022756.woff2") format("woff2-variations");
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Roboto Mono Variable;
  font-style: normal;
  font-display: swap;
  font-weight: 100 700;
  src: url("roboto-mono-latin-wght-normal.c6ab46c6.woff2") format("woff2-variations");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

html {
  scroll-behavior: smooth;
}

@font-face {
  font-family: Roboto Mono Variable;
  font-style: normal;
  font-display: swap;
  font-weight: 100 700;
  src: url("figtree-latin-wght-normal.d099c82d.woff2") format("woff2");
  unicode-range: U+2026;
}

svg.recharts-surface {
  overflow: visible;
}

.expand {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: inline-flex;
}
/*# sourceMappingURL=index.1d8e5fad.css.map */
