@import '../../node_modules/@fontsource-variable/figtree/index.css';
@import '../../node_modules/@fontsource-variable/roboto-mono/index.css';

html {
  scroll-behavior: smooth;
}

/** Use Figtree for "…" instead of Roboto Mono. */
@font-face {
  font-family: 'Roboto Mono Variable';
  font-style: normal;
  font-display: swap;
  font-weight: 100 700;
  src: url('../../node_modules/@fontsource-variable/figtree/files/figtree-latin-wght-normal.woff2')
    format('woff2');
  unicode-range: U+2026;
}

svg.recharts-surface {
  overflow: visible;
}

.expand {
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}
